import { AuthenticationState } from './AuthenticationState';

export enum MailInputError {
    NOT_VALID = 'mailNotValid',
}

export enum PasswordError {
    NO_UPPERCASE = 'passwordNoUppercase',
    NOT_MATCHING = 'passwordNotMatching',
    EMPTY = 'passwordEmpty',
    NO_LOWERCASE = 'passwordNoLowercaseChars',
    NO_DIGITS = 'passwordNoDigit',
    NO_SPECIAL_CHARS = 'passwordNoSpecialCharacters',
    TOO_SHORT = 'passwordTooShort',
}

export enum OTPError {
    TOO_SHORT = 'OTPTooShort',
}

export type PreAuthErrorChecker = (state: AuthenticationState) => Error | null;

export const getEmailError: PreAuthErrorChecker = (state) => {
    const email = state.email;
    const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isMailValid = emailRegexp.test(email);

    if (isMailValid === false) {
        return new Error(MailInputError.NOT_VALID);
    }

    return null;
};

export const getPasswordError: PreAuthErrorChecker = (state) => {
    const password = state.password;

    if (password === '') {
        return new Error(PasswordError.EMPTY);
    }

    if (state.password !== state.passwordRepeat) {
        return new Error(PasswordError.NOT_MATCHING);
    }

    const upperCaseRegExp = new RegExp(/[A-Z]/);
    if (upperCaseRegExp.test(password) === false) {
        return new Error(PasswordError.NO_UPPERCASE);
    }

    const lowerCaseRegExp = new RegExp(/[a-z]/);
    if (lowerCaseRegExp.test(password) === false) {
        return new Error(PasswordError.NO_LOWERCASE);
    }

    const digitsRegExp = new RegExp(/\d/);
    if (digitsRegExp.test(password) === false) {
        return new Error(PasswordError.NO_DIGITS);
    }

    const specialCharacterRegExp = new RegExp(/\W|_/);
    if (specialCharacterRegExp.test(password) === false) {
        return new Error(PasswordError.NO_SPECIAL_CHARS);
    }

    if (password.length < 8) {
        return new Error(PasswordError.TOO_SHORT);
    }

    return null;
};

export const getOTPError: PreAuthErrorChecker = (state) => {
    if (state.OTP.length < 6) {
        return new Error(OTPError.TOO_SHORT);
    }

    return null;
};

export const noErrorCheck: PreAuthErrorChecker = () => null;
